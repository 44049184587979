@import "reboot";

$vertical-whitespace: 10px;
$horizontal-whitespace: 20px;

body {
    background-color: #f6f6f6;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
}

h1,
h2,
h3,
h4,
.header,
.post-updated,
.hero p {
    font-style: normal;
    font-weight: 700;
}

h1,
h2,
h3,
h4 {
    margin-bottom: 1rem;
}

h1 {
    font-size: 1.8rem;
}

h2 {
    font-size: 1.2em;
    margin-top: 2rem;
}

p {
    line-height: 1.9rem;
}

li {
    line-height: 1.9rem;
    margin: 0.5rem;
}

img {
    width: 100%;
    // max-width: 100%;
    display: inline-block;
}

ul {
    padding-left: $horizontal-whitespace;
    padding-top: $vertical-whitespace;
}

code {
    background-color: #e0e0e0;
    border-radius: 3px;
    padding: 2px 6px;
    font-size: 0.9em;
}

figure {
    text-align: center;
    background-color: #e0e0e0;
    border: solid 1px #cccccc;
    border-radius: 3px;
    padding: $vertical-whitespace;
}

.container {
    margin: 0 auto;
    padding: 0 $horizontal-whitespace;
    max-width: 840px;
}

.header {
    display: block;
    padding: $vertical-whitespace * 2 0;
}

.post-list {
    list-style: none;
    padding-left: 0;
    li {
        margin: 0;
        border-bottom: solid 1px #e0e0e0;
        &:last-child {
            border-bottom: none;
        }
    }
    a {
        display: block;
        padding: $vertical-whitespace 0;
    }
}

.post-updated {
    margin-bottom: 0;
}

.code {
    background: #2e3026;
    color: #f8f8f2;
    border-radius: 5px;
    padding: $vertical-whitespace * 2 $horizontal-whitespace * 1.4;
    max-width: 1000px;
    line-height: 1.35;
}

.code code {
    background: none;
    border-radius: 0;
    padding: 0;
}

.k {
    color: #66d9ef;
}
.c {
    color: #75715e;
}
.s {
    color: #e6db74;
}
.n {
    color: #ae81ff;
}
.o {
    color: #f92672;
}
.p {
    color: #f8f8f2;
}
.a {
    color: #a6e22e;
}
.f {
    color: #a6e22e;
}
.t {
    color: #adafff;
}

.publish-note {
    font-size: 0.9em;
    color: #999999;
}

.credit {
    padding-top: $vertical-whitespace * 2;
    margin-bottom: $vertical-whitespace * 4;
    font-size: 11px;
}

.html-index {
    height: 100%;
    body {
        height: 100%;
    }
    .container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.hero {
    h1,
    p {
        font-size: 1.4rem;
        @media (min-width: 740px) {
            font-size: 2.2rem;
        }
        text-align: center;
        margin: 15px auto;
        line-height: 1.5;
    }
    h1 {
        margin-top: 0;
        max-width: 320px;
        @media (min-width: 740px) {
            max-width: 620px;
        }
    }
    p {
        max-width: 750px;
        &:last-child {
            max-width: 540px;
        }
        @media (min-width: 740px) {
            max-width: 750px;
            &:last-child {
                max-width: 540px;
            }
        }
    }
}

.html-articles {
    h1 {
        font-size: 1.4em;
        margin-bottom: 0.2em;
    }
}

.questions-or-comments {
    font-size: 1rem;
    font-style: italic;
    padding-top: $vertical-whitespace;
    margin-bottom: $vertical-whitespace * 4;
}
